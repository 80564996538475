import { PageProps } from 'gatsby';
import { FC } from 'react';
import tw, { css } from 'twin.macro';
import {
  Asterisk,
  ContentSection,
  CTA,
  D,
  Footnotes,
  H2,
  Layout,
  LI,
  NERLYNX,
  NoBreak,
  References,
  RemoveInternalPadding,
  SEO,
  UL,
  Video,
} from '~/components';
import { HeroCarousel, HeroItem } from '~/components/common/hero-carousel';
import { Frontmatter } from '~/config';
import FamilyPicture from '~/images/carousel/family-picture.jpg';
import FoldedArmsHairBig from '~/images/carousel/folded-arms-hair-big.jpg';
import FoldedArmsHair from '~/images/carousel/folded-arms-hair.jpg';
import Checkmarks from '~/images/ctas/checkmarks.svg';
import Clipboard from '~/images/ctas/clipboard.svg';
import TalkBubble from '~/images/ctas/talk-bubble.svg';
import MaryPoster from '~/images/mary-poster.jpg';
import PhotoMP4 from '~/video/Holding-up-photo.mp4';
import PhotoWeb from '~/video/Holding-up-photo.webm';
import MaryStoryMP4 from '~/video/marys-story.mp4';
import MaryStoryWeb from '~/video/marys-story.webm';

// eslint-disable-next-line
const frontmatter: Frontmatter = {
  title: 'Nerlynx HCP',
  order: 0,
  screenShoot: true,
};

const Page: FC<PageProps> = (props) => {
  return (
    <Layout>
      <SEO
        title="For healthcare professionals │ NERLYNX® (neratinib) tablets"
        description="NERLYNX is indicated for the treatment of early-stage and metastatic HER2+ breast cancer. See recurrence and progression rates data."
        ogDescription="Learn about NERLYNX treatment for early-stage and metastatic HER2+ breast cancer."
        path={props.location?.pathname}
      />
      <ContentSection
        flavor="dark"
        customCss={tw`overflow-hidden py-0 relative after:(absolute bottom-0 inset-x-0 h-2 bg-brand-yellow content[""])`}
      >
        <HeroCarousel>
          <HeroItem
            transitionHero={{
              mainText: 'You may have patients like Mary',
              disclaimerPosition: 'right',
              disclaimerText:
                'Real patient with HER2+ HR+ breast cancer who had a pCR. NERLYNX was not commercially available at the time of treatment. Photograph taken April 2013.',
            }}
            mainHero={{
              imageOrVideo: [
                { src: PhotoMP4, type: 'mp4' },
                { src: PhotoWeb, type: 'webm' },
              ],
              mainText: (
                <>
                  She's worked hard to get{' '}
                  <span tw="whitespace-pre">this far</span>
                </>
              ),
              disclaimerPosition: 'right',
              disclaimerText:
                'Real patient with HER2+ HR+ breast cancer who had a pCR. NERLYNX was not commercially available at the time of treatment. Photograph taken April 2013.',
              subText: (
                <>
                  Acting with urgency can help give patients with HER2+ eBC the
                  chance to live disease free<sup>1</sup>
                </>
              ),
              button: {
                text: 'Review the risk of recurrence',
                to: '/risk-of-recurrence',
              },
            }}
          />
          <HeroItem
            transitionHero={{
              imageOrVideo: FamilyPicture,
              disclaimerPosition: 'right',
              disclaimerText:
                'Real patient with HER2+ HR+ breast cancer who had a pCR. NERLYNX was not commercially available at the time of treatment. Photograph taken August 2014.',
              mainText: (
                <span tw="uppercase md:(font-size[1.35em] line-height[1em])">
                  PREVENT RECURRENCE<sup>1,2,</sup>
                  <Asterisk />
                </span>
              ),
              subText: (
                <span>
                  Help reduce the risk of recurrence in HER2+ eBC<sup>†</sup>
                </span>
              ),
            }}
            mainHero={{
              imageOrVideo: FoldedArmsHair,
              disclaimerPosition: 'right',
              disclaimerText:
                'Real patient with HER2+ HR+ breast cancer who had a pCR. NERLYNX was not commercially available at the time of treatment. Photograph taken August 2014.',
              mainText: (
                <>
                  Take every opportunity to offer extended adjuvant treatment
                  after trastuzumab-based therapy
                  <sup>1,2,</sup>
                  <Asterisk />
                </>
              ),
              subText: (
                <>
                  Help reduce the risk of recurrence in HER2+ eBC<sup>†</sup>
                </>
              ),
              button: {
                text: 'Explore the data',
                to: '/early-stage-breast-cancer',
              },
            }}
          />
          <HeroItem
            mainHero={{
              imageOrVideo: FoldedArmsHairBig,
              disclaimerPosition: 'right',
              disclaimerText:
                'Real patient with HER2+ HR+ breast cancer who had a pCR. NERLYNX was not commercially available at the time of treatment. Photograph taken August 2014.',
              mainText: 'Help her stay on treatment with dose escalation',
              subText: (
                <>
                  Start <NERLYNX /> at a lower dose and titrate up to the full
                  recommended dose to help manage <D />
                  <sup>3,‡</sup>
                </>
              ),
              button: {
                text: 'Manage {d} proactively',
                to: '/early-stage-breast-cancer',
              },
            }}
          />
        </HeroCarousel>
      </ContentSection>
      <ContentSection>
        <RemoveInternalPadding tw="relative bottom[6rem] flex justify-center md:(mb-0 bottom[6rem])">
          <div tw="flex flex-col px-4 gap-2 md:(flex-row h-36)">
            <CTA
              to="/guidelines/"
              bodyCss={tw`mb-14`}
              SVG={<Clipboard alt="guidelines" />}
            >
              Clinical guidelines and recommendations
            </CTA>
            <CTA
              to="/access-and-support/"
              bodyCss={tw`mb-14`}
              SVG={<TalkBubble alt="support" />}
            >
              Support and product access <NoBreak>for patients</NoBreak>
            </CTA>
            <CTA to="/resources/" SVG={<Checkmarks alt="resources" />}>
              Useful resources for your office <NoBreak>and patients</NoBreak>
            </CTA>
          </div>
        </RemoveInternalPadding>
        <UL
          yellow
          customCss={[
            tw`text-brand-gray -mt-16 md:(-mt-20)`,
            css`
              li {
                margin-bottom: 2rem;
              }
            `,
          ]}
        >
          <LI>
            The primary endpoint in ExteNET was iDFS at 2 years in the ITT
            population (N=2840): 94.2% with NERLYNX (95% CI: 92.6%-95.4%) vs
            91.9% with placebo (95% CI: 90.2%-93.2%) (HR=0.66; 95% CI:
            0.49-0.90; <i>P</i> =0.008)<sup>3</sup>
          </LI>
          <LI>
            ExteNET was a pivotal phase 3, global, multicenter, randomized,
            double-blind, placebo-controlled study of NERLYNX 240 mg/day for 1
            year (n=1420) vs placebo (n=1420) in patients with early-stage HER2+
            breast cancer (N=2840)<sup>1</sup>
          </LI>
        </UL>
        <div tw="mb-16">
          <div tw="mt-12 mb-4 lg:mb-8">
            <H2 cyan>See Mary's story</H2>
          </div>
          <Video
            videos={[
              { src: MaryStoryMP4, type: 'mp4' },
              { src: MaryStoryWeb, type: 'webm' },
            ]}
            poster={MaryPoster}
          />
        </div>
        <Footnotes
          footnotes={['randomized', 'primary-endpoint', 'controlHomepage']}
          abreviations={[
            'CI',
            'CNS',
            'eBC',
            'HR--hazard',
            'HR+',
            'iDFS',
            'ITT',
            'mBC',
            'pCR',
          ]}
          references={['1,2', '3', '1,3']}
          customCss={tw`mb-8 mt-4`}
        />
        <References
          references={['chan a', 'martin m', 'package insert', 'chan a ruiz']}
          customCss={tw`my-8 lg:mb-12`}
        />
      </ContentSection>
    </Layout>
  );
};

export default Page;
